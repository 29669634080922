<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialog = true;title = '添加游戏分类'">添加游戏分类</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="分类名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="icon" label="分类图标" align="center" :show-overflow-tooltip="true">
        <template width="90" v-slot="scope">
          <ximg :width="80" :height="80" :src="scope.row.icon"  ></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="disable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.disable == 1" style="color:#33C933">开启</div>
          <div v-else style="color:#F33333">关闭</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="del(scope.row)">删除</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="useGameType(scope.row.id,scope.row.disable == 1 ? 0 : 1)">{{scope.row.disable == 1 ? "关闭" : "开启"}}</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/编辑 -->
    <el-dialog :title="title" v-model="dialog" width="492px" top="30vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">分类名称：</div>
        <el-input v-model="dialogArr.name" placeholder="分类名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">分类图标：</div>
        <el-upload class="avatar-uploader" accept="image/jpeg,image/png,image/webp,image/gif" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
          <ximg v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" :enlarge="false" class="avatar" ></ximg>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="note">支持扩展名:.png .jpg .gif</div>
      </div>
      <div class="form_box">
        <div class="form_title">游戏分类权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="游戏分类权重" size="small" type="number"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-if="title=='添加游戏分类'" @click="addGameType">确认添加</el-button>
          <el-button type="primary" v-else @click="updateGameType">确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getGameTypeList, useGameType, addGameType, updateGameType, del } from "@/api/game/type";
import { upload } from "@/api/common/common";
export default {
  name: "gameType",
  components: {},
  data() {
    return {
      search: {
        //搜索
      },
      tableData: [], //数据
      row: {},
      title: "",
      weight: 0,
      dialog: false,
      dialogArr: {
        name: "",
        url: "",
        imgUrl: "",
        weight: "",
      },
      src: "",
      srcList: [], //放大图片
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      getGameTypeList([])
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 禁用启用
    useGameType(id, disable) {
      useGameType([
        { key: "type_id", val: id },
        { key: "disable", val: disable },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 添加
    addGameType(){
      addGameType([
        { key: "name", val: this.dialogArr.name },
        { key: "icon", val: this.dialogArr.url },
        { key: "weight", val: this.dialogArr.weight },
      ]).then((res) => {
        if (res.code == 0) {
          this.$message({ message: "添加成功！", type: "success" });
          this.dialog = false;
          this.getList();
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    // 编辑
    editor(row) {
      this.row = row;
      this.title = `编辑游戏分类(ID:${row.id})`;
      this.dialogArr = {
        name: row.name, //标题
        weight: row.weight, //权重
        url: row.icon,
        imgUrl: this.$store.getImage(row.icon), // 图片完整地址
      };
      this.dialog = true;
    },
    // 确认编辑
    updateGameType() {
      updateGameType([
        { key: "type_id", val: this.row.id },
        { key: "name", val: this.dialogArr.name },
        { key: "icon", val: this.dialogArr.url },
        { key: "weight", val: this.dialogArr.weight },
      ]).then((res) => {
          if (res.code == 0) {
            this.$message({ message: "编辑成功!", type: "success" });
            this.dialog = false;
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除分类
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除分类：" + row.name + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      ).then(() => {
          del([
          { key: "type_id", val: row.id },
          ]).then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: "删除成功！", type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            }).catch((err) => {
              console.log(err);
            });
        }).catch((err) => {
          console.log(err);
        });
    },
    // 关闭弹窗清空
    closeDialog() {
      this.row = {};
      this.title = "";
      this.dialogArr = {
        name: "", //标题
        weight: "", //权重
        url: "",
        imgUrl: "",
      };
    },
    //上传图片
    upload(param) {
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "game" ,isencode:"true" })
          .then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.url = res.data.filename;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    // 图片上传前校验拦截
    beforeAvatarUpload(file) {
      const type = file.type;
      const isType =  type === "image/jpeg" || type === "image/png" || type === "image/webp" || type === "image/gif"

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传图片格式错误!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2MB!");
      }
      return isType && isLt2M;
    },
    //放大图片功能
    amplification(url) {
      this.src = url;
      this.srcList = [];
      this.srcList.push(url);
      setTimeout(() => {
        document.getElementsByClassName("bigimg")[0].childNodes[0].click();
      }, 50);
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
/* 图片上传部分 */
.form_box >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.form_box >>> .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.form_box >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.form_box >>> .avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>